import React, { createContext, useReducer } from "react";

export const actionTypes = {
  UPDATE_STATE: "UPDATE_PLAN",
  UPDATE_PER_INFO: "UPDATE_PER_INFO",
  UPDATE_BILL_INFO: "UPDATE_BILL_INFO",
};

const plan = {
  price: {
    kj: {
      full: 325,
      half: 100,
    },
    all: {
      full: 299,
      half: 175,
    },
  },
  zips: [
    "11220",
    "11209",
    "11213",
    "10970",
    "11234",
    "11210",
    "10901",
    "08527",
    "11203",
    "10954",
    "08755",
    "11218",
    "11230",
    "10314",
    "10956",
    "11205",
    "11249",
    "11211",
    "08701",
    "10952",
    "10977",
    "11206",
    "11219",
    "11204",
    "10930",
    "10950",
    "07305",
  ],
  zip: "",
  plan: "full",
};

const perInfo = {
  firstName: "",
  lastName: "",
  address: "",
  apt: "",
  city: "",
  stt: "",
  zip: "",
  addr2: "",
  homePhone: "",
  cellPhone: "",
  email: "",
  returningCustomer: false,
  promoCode: "",
};

const billInfo = {
  firstName: "",
  lastName: "",
  address: "",
  apt: "",
  city: "",
  stt: "",
  zip: "",
  sameAs: false,
  paymentId: "",
};

const initialState = {
  plan,
  perInfo,
  billInfo,
  loading: false,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    console.log("NEW ACTION", action);
    switch (action.type) {
      case actionTypes.UPDATE_PLAN:
        const newPlanState = { ...state.plan, ...action.payload };
        const newPState = { ...state, plan: newPlanState };
        return newPState;
      case actionTypes.UPDATE_PER_INFO:
        const newPerInfoState = { ...state.perInfo, ...action.payload };
        const newPIState = { ...state, perInfo: newPerInfoState };
        return newPIState;
      case actionTypes.UPDATE_BILL_INFO:
        const newBillInfoState = { ...state.billInfo, ...action.payload };
        const newBState = { ...state, billInfo: newBillInfoState };
        return newBState;
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
