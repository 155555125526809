import { InputText } from "primereact/inputtext";
import React, { useContext, useState } from "react";

import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import validator from "validator";
import { actionTypes, store } from "../store";

const PersonalInfo = ({ navigate, submitPerInfo }) => {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [address, setAddress] = useState("");
  // const [apt, setApt] = useState("");
  // const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  // const [zip, setZip] = useState("");
  // const [addr2, setAddr2] = useState("");
  // const [homePhone, setHomePhone] = useState("");
  // const [cellPhone, setCellPhone] = useState("");
  // const [email, setEmail] = useState("");
  // const [returningCustomer, setReturningCustomer] = useState(false);

  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [validated, setValidated] = useState(false);

  const submit = () => {
    if (validateInput()) {
      navigate(2);
    } else {
      setShowErrors(true);
    }
    // console.log(errors);
    // console.log(Object.keys(errors).length);
    // if (Object.keys(errors).length > 0)
    // else
  };

  const { state, dispatch } = useContext(store);
  const {
    firstName,
    lastName,
    address,
    apt,
    city,
    stt,
    zip,
    addr2,
    homePhone,
    cellPhone,
    email,
    returningCustomer,
  } = state.perInfo;

  const update = (e) => {
    dispatch({
      type: actionTypes.UPDATE_PER_INFO,
      payload: { [e.target.id]: e.target.value },
    });
  };

  const setReturningCustomer = (e) => {
    dispatch({
      type: actionTypes.UPDATE_PER_INFO,
      payload: { returningCustomer: e.value },
    });
  };

  const validateInput = () => {
    console.log("validating");
    let errs = {};
    // First name
    const checkfirstName = validator.isLength(firstName, { min: 2 });
    if (!checkfirstName) errs.firstName = "Please enter your first name";
    // Last name
    const checklastName = validator.isLength(lastName, { min: 2 });
    if (!checklastName) errs.lastName = "Please enter your last name";
    // Address
    const checkaddress = validator.isLength(address, { min: 3 });
    if (!checkaddress) errs.address = "Please enter your address";
    // city
    const checkcity = validator.isLength(city, { min: 3 });
    if (!checkcity) errs.city = "Please enter your city";
    // state
    const checkstt = validator.isLength(stt, { min: 2 });
    if (!checkstt) errs.stt = "Please enter your state";
    // zip
    const checkzip = validator.isLength(zip, { min: 3 });
    if (!checkzip) errs.zip = "Please enter your zip code";
    // phone
    const checkhomePhone = validator.isLength("" + homePhone, { min: 7 });
    if (!checkhomePhone) errs.homePhone = "Please enter your phone number";
    // cell
    const checkcellPhone = validator.isLength("" + cellPhone, { min: 7 });
    if (!checkcellPhone) errs.cellPhone = "Please enter your cell number";
    // email
    const checkemail = validator.isEmail(email);
    if (!checkemail) errs.email = "Please enter your email";
    console.log(errs);
    setErrors(errs);
    if (Object.keys(errs).length === 0) {
      setValidated(true);
      return true;
    } else return false;
    // console.log(validated);
    // console.log(errors);
  };

  return (
    <div>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="firstName">First Name*</label>
          <InputText
            value={firstName}
            id="firstName"
            onChange={update}
            onBlur={validateInput}
          />
          {showErrors && errors.firstName && (
            <small className="p-error p-d-block">{errors.firstName}</small>
          )}
        </div>
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="lastName">Last Name*</label>

          <InputText
            value={lastName}
            id="lastName"
            onChange={update}
            onBlur={validateInput}
          />
          {showErrors && errors.lastName && (
            <small className="p-error p-d-block">{errors.lastName}</small>
          )}
        </div>
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="address">Address*</label>

          <InputText
            value={address}
            onBlur={validateInput}
            id="address"
            onChange={update}
          />
          {showErrors && errors.address && (
            <small className="p-error p-d-block">{errors.address}</small>
          )}
        </div>
        {/* <div className="p-col-12 p-md-6 p-d-flex"> */}
        <div className="p-field p-col-6 p-md-1">
          <label htmlFor="apt">Apt</label>

          <InputText
            value={apt}
            onBlur={validateInput}
            id="apt"
            onChange={update}
          />
        </div>
        <div className="p-field p-col-12 p-md-5">
          <label htmlFor="addr2">Address Line 2</label>

          <InputText
            value={addr2}
            onBlur={validateInput}
            id="addr2"
            onChange={update}
          />
        </div>
        <div className="p-field p-col-6 p-md-4">
          <label htmlFor="city">City*</label>

          <InputText
            value={city}
            onBlur={validateInput}
            id="city"
            onChange={update}
          />
          {showErrors && errors.city && (
            <small className="p-error p-d-block">{errors.city}</small>
          )}
        </div>
        <div className="p-field p-col-6 p-md-4">
          <label htmlFor="stt">State*</label>

          <InputText
            value={stt}
            onBlur={validateInput}
            id="stt"
            onChange={update}
          />
          {showErrors && errors.stt && (
            <small className="p-error p-d-block">{errors.stt}</small>
          )}
        </div>
        <div className="p-field p-col-6 p-md-4">
          <label htmlFor="zip">Zip Code*</label>

          <InputText
            value={zip}
            onBlur={validateInput}
            id="zip"
            onChange={update}
          />
          {showErrors && errors.zip && (
            <small className="p-error p-d-block">{errors.zip}</small>
          )}
        </div>
        {/* </div> */}

        <div className="p-field p-col-12 p-md-3">
          <label htmlFor="homePhone">Home Phone*</label>

          <InputText
            value={homePhone}
            onBlur={validateInput}
            id="homePhone"
            onChange={update}
          />
          {showErrors && errors.homePhone && (
            <small className="p-error p-d-block">{errors.homePhone}</small>
          )}
        </div>
        <div className="p-field p-col-12 p-md-3">
          <label htmlFor="cellPhone">Cell Phone*</label>

          <InputText
            value={cellPhone}
            onBlur={validateInput}
            id="cellPhone"
            onChange={update}
          />
          {showErrors && errors.cellPhone && (
            <small className="p-error p-d-block">{errors.cellPhone}</small>
          )}
        </div>
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="email">Email Address*</label>

          <InputText
            value={email}
            onBlur={validateInput}
            id="email"
            onChange={update}
          />
          {showErrors && errors.email && (
            <small className="p-error p-d-block">{errors.email}</small>
          )}
        </div>
        <div className="p-field p-col-12 p-md-6 p-d-flex p-ai-center">
          <label className="p-mr-4" htmlFor="returningCustomer">
            Returning Customer
          </label>

          <InputSwitch
            checked={returningCustomer}
            id="returningCustomer"
            onChange={(e) => setReturningCustomer(e)}
          />
        </div>
      </div>
      <div className="p-d-flex p-jc-between">
        <Button
          onClick={() => navigate(0)}
          label="Back"
          icon="pi pi-arrow-circle-left"
          iconPos="left"
        />
        <Button
          onClick={submit}
          label="Next"
          icon="pi pi-arrow-circle-right"
          iconPos="right"
          // disabled={!validated}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
