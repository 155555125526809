import { Steps } from "primereact/steps";
import { Card } from "primereact/card";
import React, { useContext, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Subscription from "./components/Subscription";
import PersonalInfo from "./components/PersonalInfo";
import BillingInfo from "./components/BillingInfo";
import Confirmation from "./components/Confirmation";
import ClockLoader from "react-spinners/ClipLoader";
import { Dialog } from "primereact/dialog";

import { css } from "@emotion/core";

import Logo from "./assets/logo.png";
import axios from "axios";
import { store } from "./store";
import { Button } from "primereact/button";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51IJSq4GJwWsNfC0CpApenjIOtufF8fl6Vuup4O47iC4BqXGMcPQffeU463xxuI93t4KbOthfHsR77TywhYLiUSLF00MP5UZlOt"
);

function App() {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [activeIndex, setActiveIndex] = useState(0);
  const [billInfoState, setBillInfoState] = useState({});
  const [perInfoState, setPerInfoState] = useState({});
  const [planInfo, setPlanInfo] = useState({});

  const interactiveItems = [
    {
      label: "Subscription",
      command: (event) => {},
    },
    {
      label: "Personal Info",
      command: (event) => {},
    },
    {
      label: "Billing Info",
      command: (event) => {},
    },
  ];

  const postURI = "https://hundredbe.herokuapp.com/email";
  // const postURI = "http://localhost:8000/email";

  const { state } = useContext(store);
  const { plan, perInfo, billInfo } = state;
  const [confirm, setConfirm] = useState(false);
  const [err, setErr] = useState(false);

  const submit = () => {
    setLoading(true);
    axios
      .post(postURI, {
        plan,
        perInfo,
        billInfo,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.data.success) {
          setConfirm(true);
        } else {
          setErr(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        setErr(true);
        console.error(e);
      });
  };

  let [loading, setLoading] = useState(false);

  const footer = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          window.location.reload(true);
          setConfirm(false);
        }}
      />
    </div>
  );
  const footerErr = (
    <div>
      <Button label="Ok" icon="pi pi-check" onClick={() => setErr(false)} />
    </div>
  );

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        {loading && (
          <div className="loading">
            <ClockLoader loading={loading} size="100" />
          </div>
        )}

        <Dialog
          header="Suucess!"
          visible={confirm}
          style={{ width: "50vw" }}
          footer={footer}
          onHide={() => {
            Location.reload(true);
            setConfirm(false);
          }}
        >
          <p>
            Your subscription is beeing proccessed, new subscription takes 2
            weeks proccessing time untill you will start recieving bla bla bla
          </p>
        </Dialog>
        <Dialog
          header="Error!"
          visible={err}
          style={{ width: "50vw" }}
          footer={footerErr}
          onHide={() => setErr(false)}
        >
          <p>An error accured, please try again.</p>
        </Dialog>

        <main style={{ maxWidth: "1200px", margin: "50px auto" }}>
          <Card>
            <img
              src={Logo}
              alt="Hundred Publications"
              height="120"
              style={{
                display: "block",
                margin: "20px auto",
                marginBottom: "50px",
              }}
            />
            <Steps
              model={interactiveItems}
              activeIndex={activeIndex}
              onSelect={(e) => setActiveIndex(e.index)}
              readOnly={true}
            />

            {activeIndex === 0 && (
              <Subscription
                navigate={(i) => setActiveIndex(i)}
                submitPlanInfo={setPlanInfo}
              />
            )}
            {activeIndex === 1 && (
              <PersonalInfo
                navigate={(i) => setActiveIndex(i)}
                submitPerInfo={setPerInfoState}
              />
            )}
            {activeIndex === 2 && (
              <BillingInfo
                navigate={(i) => setActiveIndex(i)}
                submitBillInfo={setBillInfoState}
              />
            )}
            {activeIndex === 3 && (
              <Confirmation
                navigate={(i) => setActiveIndex(i)}
                submit={submit}
                planInfo={planInfo}
                perInfoState={perInfoState}
                billInfoState={billInfoState}
              />
            )}
          </Card>
        </main>
      </div>
    </Elements>
  );
}

export default App;
