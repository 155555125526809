import { Button } from "primereact/button";
import { useContext } from "react";
import { store } from "../store";
const kj = ["10930", "10950"];
const Confirmation = ({ navigate, submit }) => {
  const { state, dispatch } = useContext(store);

  const { plan, perInfo, billInfo } = state;

  return (
    <div>
      <h2>Confirmation</h2>
      <h3>Subscribers Info</h3>
      <p>
        Name:{" "}
        <b>
          {perInfo.firstName} {perInfo.lastName}
        </b>
      </p>
      <p>
        Address:{" "}
        <b>
          {perInfo.address} {perInfo.addr2} {perInfo.apt} {perInfo.city}{" "}
          {perInfo.state} {perInfo.zip}
        </b>
      </p>
      <p>
        Phone: <b>{perInfo.homePhone}</b>
      </p>
      <p>
        Cell: <b>{perInfo.cellPhone || "---"}</b>
      </p>
      <p>
        Email: <b>{perInfo.email}</b>
      </p>
      <h3>Billing Info</h3>
      <p>
        Card: <b>xxxxxxxxxxxx{billInfo.cardNumber.substr(-4)}</b>
      </p>
      <p>
        Billing Address:{" "}
        {billInfo.sameAs ? (
          "Same as delivery"
        ) : (
          <b>
            {billInfo.address} {billInfo.apt} {billInfo.city} {billInfo.state}{" "}
            {billInfo.zip}
          </b>
        )}{" "}
      </p>
      <h3>Subscription Plan</h3>
      {console.log(plan.zip)}
      {console.log(plan.plan)}
      {kj.includes(plan.zip) && (
        <p>Full Year ${plan.price.kj.full} - 50 Issues</p>
      )}
      {!kj.includes(plan.zip) && plan.plan === "full" && (
        <p>Full Year ${plan.price.all.full} - 50 Issues</p>
      )}
      {!kj.includes(plan.zip) && plan.plan === "half" && (
        <p>Half Year ${plan.price.all.half} - 25 Issues</p>
      )}

      <Button
        label="Submit"
        onClick={submit}
        className="p-button-raised p-button-lg"
      />
      <div className="p-d-flex p-jc-between p-mt-3">
        <Button
          onClick={() => navigate(2)}
          label="Back"
          icon="pi pi-arrow-circle-left"
          iconPos="left"
        />
      </div>
    </div>
  );
};

export default Confirmation;
