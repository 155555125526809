import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { RadioButton } from "primereact/radiobutton";
import { useContext, useEffect, useState } from "react";
import { actionTypes, store } from "../store";

const kj = ["10930", "10950"];

const Subscription = ({ navigate, submitPlanInfo }) => {
  const [zipOk, setZipOk] = useState(false);
  // const [subType, setSubType] = useState("full");
  const [fullPrice, setFullPrice] = useState(0);
  const [halfPrice, setHalfPrice] = useState(0);
  // const [err, setErr] = useState("");

  const { state, dispatch } = useContext(store);
  const { zip, zips, price, plan } = state.plan;
  // console.log(globalState);

  const setSubType = (e) => {
    dispatch({ action: actionTypes.UPDATE_PLAN, payload: { plan: e.value } });
  };

  const checkZip = () => {
    if (zips.includes(zip)) {
      if (kj.includes(zip)) {
        // KJ
        setFullPrice(price.kj.full);
        setHalfPrice(price.kj.half);
        setZipOk(true);
      } else {
        // Other
        setFullPrice(price.all.full);
        setHalfPrice(price.all.half);
        setZipOk(true);
      }
    } else {
      // Out of area
      setZipOk(false);
    }
  };

  useEffect(() => {
    checkZip();
  }, []);

  const submit = () => {
    const planInfo = {
      price: plan === "full" ? fullPrice : halfPrice,
      plan: plan,
    };
    submitPlanInfo(planInfo);
    if (plan !== "") navigate(1);
  };

  return (
    <div>
      <h2>Choose your subscription plan</h2>
      <div className="p-field p-col-12 p-md-12">
        <label htmlFor="zip" className="p-mr-3">
          Enter zip code to check availability
        </label>

        <InputText
          autoFocus={true}
          value={zip}
          id="zip"
          onChange={(e) =>
            dispatch({
              type: actionTypes.UPDATE_PLAN,
              payload: { zip: e.target.value },
            })
          }
          onBlur={checkZip}
          onKeyUp={checkZip}
        />
        {/* <AutoComplete
          value={zip}
          suggestions={zips}
          dropdown
          // completeMethod={searchCountry}
          onChange={(e) => setZip(e.value)}
        /> */}
        {/* <Dropdown
          value={zip}
          options={zips}
          onChange={(e) => setZip(e.value)}
          placeholder="Select a City"
          filter
        /> */}
        {!zipOk && zip && (
          <small className="p-error p-d-block">
            Subscription not available in your location
            <br />
            Please contact our office for alternate delivery options available
            in your area. 718-289-0100.
          </small>
        )}
        {zipOk && !kj.includes(zip) && (
          <>
            <div className="p-field-radiobutton p-mt-4">
              <RadioButton
                value="full"
                name="plan"
                id="full"
                onChange={setSubType}
                checked={plan === "full"}
              />
              <label htmlFor="full">Full Year ${fullPrice} - 50 Issues</label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                value="half"
                name="plan"
                id="half"
                onChange={setSubType}
                checked={plan === "half"}
              />
              <label htmlFor="half">Half Year ${halfPrice} - 25 Issues</label>
            </div>
          </>
        )}

        {zipOk && kj.includes(zip) && <p>Full Year ${fullPrice} - 50 Issues</p>}
      </div>
      <div className="p-d-flex p-jc-end ">
        <Button
          onClick={submit}
          label="Next"
          icon="pi pi-arrow-circle-right"
          iconPos="right"
          disabled={!zipOk}
        />
      </div>
    </div>
  );
};

export default Subscription;
