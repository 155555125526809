import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import React, { useContext, useEffect, useState } from "react";
import ClockLoader from "react-spinners/ClipLoader";
import { Dialog } from "primereact/dialog";

import { css } from "@emotion/core";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import validator from "validator";
import { store, actionTypes } from "../store";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";

const postURI = "https://hundredbe.herokuapp.com/email";
// const postURI = "http://localhost:8000/email";
const kj = ["10930", "10950"];

const BillingInfo = ({ navigate, submitBillInfo }) => {
  // const [cardNumber, setCardNumber] = useState("");
  // const [exp, setExp] = useState("");
  // const [cvv, setCvv] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [address, setAddress] = useState("");
  // const [apt, setApt] = useState("");
  // const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  // const [zip, setZip] = useState("");
  // const [sameAs, setSameAs] = useState(false);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  // const submit = () => {
  //   const billInfo = {
  //     cardNumber,
  //     exp,
  //     cvv,
  //     firstName,
  //     lastName,
  //     address,
  //     apt,
  //     city,
  //     stt,
  //     zip,
  //     sameAs,
  //   };
  //   submitBillInfo(billInfo);
  //   navigate(3);
  // };

  const { state, dispatch } = useContext(store);
  const { plan, perInfo, billInfo } = state;
  const {
    cardNumber,
    exp,
    cvv,
    firstName,
    lastName,
    address,
    apt,
    city,
    stt,
    zip,
    promoCode,
    sameAs,
  } = state.billInfo;

  // const {}= state.plan

  const update = (e) => {
    dispatch({
      type: actionTypes.UPDATE_BILL_INFO,
      payload: { [e.target.id]: e.target.value },
    });
  };

  const updateSameAs = (e) => {
    dispatch({
      type: actionTypes.UPDATE_BILL_INFO,
      payload: { sameAs: e.value },
    });
  };

  const validateInput = () => {
    console.log("validating");
    let errs = {};
    // // Card number
    // const checkCC = validator.isCreditCard(cardNumber);
    // if (!checkCC) errs.cardNumber = "Please enter your card number";
    // // Exp
    // const checkexp = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(exp);
    // if (!checkexp) errs.exp = "Please enter a valid expiration date";
    // // Cvv
    // const checkcvv = validator.isLength(cvv, { min: 3 });
    // if (!checkcvv) errs.cvv = "Please enter a valid cvv";

    if (!sameAs) {
      // First name
      const checkfirstName = validator.isLength(firstName, { min: 2 });
      if (!checkfirstName) errs.firstName = "Please enter your first name";
      // Last name
      const checklastName = validator.isLength(lastName, { min: 2 });
      if (!checklastName) errs.lastName = "Please enter your last name";
      // Address
      const checkaddress = validator.isLength(address, { min: 3 });
      if (!checkaddress) errs.address = "Please enter your address";
      // city
      const checkcity = validator.isLength(city, { min: 2 });
      if (!checkcity) errs.city = "Please enter your city";
      // state
      const checkstt = validator.isLength(stt, { min: 3 });
      if (!checkstt) errs.stt = "Please enter your state";
      // zip
      const checkzip = validator.isLength(zip, { min: 3 });
      if (!checkzip) errs.zip = "Please enter your zip code";
    }
    setErrors(errs);
    if (Object.keys(errs).length === 0) {
      setValidated(true);
      return true;
    } else return false;
  };

  const elements = useElements();
  const stripe = useStripe();
  const [proccessing, setProcessing] = useState(false);
  const [err, setErr] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const subPlans = () => {
    if (kj.includes(plan.zip)) return plan.price.kj.full;
    if (!kj.includes(plan.zip) && plan.plan === "full")
      return plan.price.all.full;
    if (!kj.includes(plan.zip) && plan.plan === "half")
      return plan.price.all.half;
  };

  const submit = async () => {
    if (validateInput()) {
      setProcessing(true);
      // console.table({ CardNumberE, CardExpE, CardCvcE });
      // const cardElement = elements.getElement(CardNumberElement);
      // const CardExpE = elements.getElement(CardExpiryElement);
      // const CardCvcE = elements.getElement(CardCvcElement);

      // const perInfo = {
      //   firstName: "",
      //   lastName: "",
      //   address: "",
      //   apt: "",
      //   city: "",
      //   stt: "",
      //   zip: "",
      //   addr2: "",
      //   homePhone: "",
      //   cellPhone: "",
      //   email: "",
      //   returningCustomer: false,
      // };

      const billing_details = {};
      billing_details.address = {};
      billing_details.email = state.perInfo.email;
      billing_details.phone = state.perInfo.homePhone;
      if (sameAs) {
        billing_details.name = `${state.perInfo.firstName} ${state.perInfo.lastName}`;
        billing_details.address.line1 = state.perInfo.address;
        billing_details.address.line2 = state.perInfo.addr2;
        billing_details.address.city = state.perInfo.city;
        billing_details.address.state = state.perInfo.stt;
        billing_details.address.postal_code = state.perInfo.zip;
      } else {
        billing_details.name = `${firstName} ${lastName}`;
        billing_details.address.line1 = address;
        billing_details.address.city = city;
        billing_details.address.state = stt;
        billing_details.address.postal_code = zip;
      }

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details,
        },
      });
      console.log("Paying");
      console.log(payload);
      if (payload.error) {
        setErr(`Payment failed ${payload.error.message}`);
        console.log(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        dispatch({
          type: actionTypes.UPDATE_BILL_INFO,
          payload: { paymentId: payload.paymentIntent.id },
        });
        console.log("success");
        axios
          .post(postURI, {
            plan,
            perInfo,
            billInfo: { ...billInfo, paymentId: payload.paymentIntent.id },
          })
          .then((res) => {
            console.log(res);
            setProcessing(false);
            if (res.data.success) {
              setConfirm(true);
            } else {
              setErr("An error occurred, please contact customer service");
            }
          })
          .catch((e) => {
            setProcessing(false);
            setErr("An error occurred, please contact customer service");
            console.error(e);
          });

        // navigate(3);
        // setConfirm(true);
      }
    } else {
      setShowErrors(true);
    }
    // console.log(errors);
    // console.log(Object.keys(errors).length);
    // if (Object.keys(errors).length > 0)
    // else
  };

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch("https://hundredbe.herokuapp.com/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: Number(subPlans()) * 100 }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
      });
  }, []);

  const footerErr = (
    <div>
      <Button label="Ok" icon="pi pi-check" onClick={() => setErr(false)} />
    </div>
  );

  const footer = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          window.location.reload(true);
          setConfirm(false);
        }}
      />
    </div>
  );

  return (
    <div>
      {proccessing && (
        <div className="loading">
          <ClockLoader loading={proccessing} size="100" />
        </div>
      )}

      <Dialog
        header="Error!"
        visible={err ? true : false}
        style={{ width: "50vw" }}
        footer={footerErr}
        onHide={() => setProcessing(false)}
      >
        <p>{err}</p>
      </Dialog>
      <Dialog
        header="Suucess!"
        visible={confirm}
        style={{ width: "50vw" }}
        footer={footer}
        onHide={() => {
          window.location.reload(true);
          setConfirm(false);
        }}
      >
        <p>
          Your subscription has been successfully processed. Please allow up to
          2 weeks for your first issue to arrive. If you ever have a missed
          delivery please contact our office so we can credit your account.
          <br />
          <br />
          <small>*All subscriptions are non-refundable. </small>
        </p>
      </Dialog>
      <h2>Payment Info</h2>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="cardNumber">Credit Card</label>
          <CardNumberElement
            className="p-inputtext p-component"
            id="cardNumber"
            options={{
              style: {
                base: {
                  color: "#000",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        <div className="p-field p-col-6 p-md-3">
          <label htmlFor="cvv">Expiration date</label>
          <CardExpiryElement
            id="exp"
            className="p-inputtext p-component"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        <div className="p-field p-col-6 p-md-3">
          <label htmlFor="cvv">CVC</label>
          <CardCvcElement
            className="p-inputtext p-component"
            id="cvv"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        {/* <div className="p-field p-col-12 p-md-6">
          <label htmlFor="cardNumber">Credit Card</label>
          <InputText
            value={cardNumber}
            id="cardNumber"
            onBlur={validateInput}
            onChange={update}
          />
          {showErrors && errors.cardNumber && (
            <small className="p-error p-d-block">{errors.cardNumber}</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-d-flex">
          <div className="p-field p-col-6">
            <label htmlFor="exp">Expiration date</label>

            <InputText
              value={exp}
              id="exp"
              onBlur={validateInput}
              onChange={update}
            />
            {showErrors && errors.exp && (
              <small className="p-error p-d-block">{errors.exp}</small>
            )}
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="cvv">CVV</label>

            <InputText
              value={cvv}
              id="cvv"
              onBlur={validateInput}
              onChange={update}
            />
            {showErrors && errors.cvv && (
              <small className="p-error p-d-block">{errors.cvv}</small>
            )}
          </div> */}
        {/* </div> */}
        <h2>Billing Info</h2>
        <div className="p-field p-col-12 p-md-12 p-d-flex p-ai-center">
          <label className="p-mr-4" htmlFor="sameAs">
            Same as Delivery
          </label>

          <InputSwitch
            checked={sameAs}
            id="sameAs"
            onBlur={validateInput}
            onChange={updateSameAs}
          />
        </div>
        {!sameAs && (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="firstName">First Name</label>
              <InputText
                value={firstName}
                id="firstName"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.firstName && (
                <small className="p-error p-d-block">{errors.firstName}</small>
              )}
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="lastName">Last Name</label>

              <InputText
                value={lastName}
                id="lastName"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.lastName && (
                <small className="p-error p-d-block">{errors.lastName}</small>
              )}
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="address">Address</label>

              <InputText
                value={address}
                id="address"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.address && (
                <small className="p-error p-d-block">{errors.address}</small>
              )}
            </div>
            {/* <div className="p-col-12 p-md-6 p-d-flex"> */}
            <div className="p-field p-col-6 p-md-3">
              <label htmlFor="apt">Apt</label>

              <InputText
                value={apt}
                id="apt"
                onBlur={validateInput}
                onChange={update}
              />
            </div>
            <div className="p-field p-col-6 p-md-3">
              <label htmlFor="city">City</label>

              <InputText
                value={city}
                id="city"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.city && (
                <small className="p-error p-d-block">{errors.city}</small>
              )}
            </div>
            <div className="p-field p-col-6 p-md-3">
              <label htmlFor="stt">State</label>

              <InputText
                value={stt}
                id="stt"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.stt && (
                <small className="p-error p-d-block">{errors.stt}</small>
              )}
            </div>
            <div className="p-field p-col-6 p-md-3">
              <label htmlFor="zip">Zip Code</label>

              <InputText
                value={zip}
                id="zip"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.zip && (
                <small className="p-error p-d-block">{errors.zip}</small>
              )}
            </div>
            {/* </div> */}
          </div>
        )}

      </div>

      <h2>Promotions</h2>
      <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="promotcode">Promo Code</label>
              <InputText
                value={promoCode}
                id="promoCode"
                onBlur={validateInput}
                onChange={update}
              />
              {showErrors && errors.promoCode && (
                <small className="p-error p-d-block">{errors.promoCode}</small>
              )}
            </div>
          </div>


      <div className="p-d-flex p-jc-between">
        <Button
          onClick={() => navigate(1)}
          label="Back"
          icon="pi pi-arrow-circle-left"
          iconPos="left"
        />
        <Button
          className="p-button-lg"
          onClick={submit}
          label="Subscribe!"
          icon="pi pi-star"
          iconPos="right"
        />
      </div>
    </div>
  );
};

export default BillingInfo;
